img {
  max-width: 100%;
}

ul:focus {
  outline: none !important;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

.animate-flicker {
  animation: flickerAnimation 1.5s infinite ease-in-out;
}

@keyframes highlight {
  0% {
    background-color: rgba(16, 156, 241, 0.02);
  }
  20% {
    background-color: rgba(16, 156, 241, 0.03);
  }
  40% {
    background-color: rgba(16, 156, 241, 0.04);
  }
  60% {
    background-color: rgba(16, 156, 241, 0.03);
  }
  80% {
    background-color: rgba(16, 156, 241, 0.02);
  }
  100% {
    background-color: rgba(16, 156, 241, 0.02);
  }
}
